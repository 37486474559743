import { Show } from 'solid-js';
import { getRequestEvent, isServer } from 'solid-js/web';
import { IconShareIosExport } from '../icons/share-ios-export';
import { IconShareAndroid } from '../icons/share-android';
import type { IconProps } from '../icon';

const isApple = () => {
	const ua = (isServer ? getRequestEvent()?.request.headers.get('user-agent') : window.navigator.userAgent) ?? '';
	return (
		['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(ua) ||
		// iPad on iOS 13 detection
		ua.includes('Mac')
	);
};
export function IconShare(props: IconProps) {
	return (
		<Show when={isApple()} fallback={<IconShareAndroid {...props} />}>
			<IconShareIosExport {...props} />
		</Show>
	);
}
